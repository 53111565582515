import { createSlice } from "@reduxjs/toolkit";

export const HelperSlice = createSlice({
  name: "helpers",
  initialState: {
    viewDownloadModal: false,
    reScheduleModal: false,
    membersModal: false,
    meetingSchedule: false,
    inventory: [],
    loading: false,
    error: null,
  },
  reducers: {
    showReScheduleModal: (state) => {
      state.reScheduleModal = true;
    },
    closeReScheduleModal: (state) => {
      state.reScheduleModal = false;
    },

    showViewDownloadModal: (state) => {
      state.viewDownloadModal = true;
    },
    closeViewDownloadModall: (state) => {
      state.viewDownloadModal = false;
    },

    showMembersModal: (state, action) => {
      state.membersModal = action.payload;
    },
    showMeetingsReschduleModal: (state, action) => {
      state.meetingSchedule = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  showViewDownloadModal,
  closeViewDownloadModall,
  showReScheduleModal,
  closeReScheduleModal,
  showMembersModal,
  showMeetingsReschduleModal,
} = HelperSlice.actions;

export default HelperSlice.reducer;
